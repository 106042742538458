import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PUC from "components/common/PageUnderConstruction";
export const _frontmatter = {
  "name": "Razorpay",
  "shortInfo": "Building Platform Tools & Design System to enable Frontend Engineers to build faster & better. Built payments app for creating payment links, issuing refunds, and managing settlements with React Native and GraphQL.",
  "position": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PUC mdxType="PUC" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      